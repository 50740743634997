import { createTheme } from '@mui/material/styles';

import { typography } from '@/Styles';

const AccentMainColor = '#035DAD';
export const SquareMainColor = '#2B73D9';
export const EbChintaiMainColor = '#1698D9';
export const ManuallyInputMainColor = '#757575';
export const ActiveMenuColor = 'rgba(241, 246, 252)';

export const ShinchokuTableColor = {
  primary: '#a9c4ef33',
  secondary: '#f9cb7633',
  default: '#bdbdbd33',
  white: 'FFFFFF',
};

let theme = createTheme({
  palette: {
    mode: 'light',
    text: {
      primary: 'rgba(0,0,0,0.87)',
      secondary: 'rgba(0,0,0,0.60)',
      disabled: 'rgba(0,0,0,0.38)',
    },
    background: {
      default: '#F4F4F4',
      paper: '#FFFFFF',
    },
    common: {
      black: '#000000',
      white: '#FFFFFF',
    },
    action: {
      active: 'rgba(0,0,0,0.54)',
      disabled: 'rgba(0,0,0,0.26)',
      hover: 'rgba(0,0,0,0.04)',
      focus: 'rgba(0,0,0,0.12)',
      selected: 'rgba(0,0,0,0.08)',
      disabledBackground: 'rgba(0,0,0,0.12)',
    },
    primary: {
      main: AccentMainColor,
      light: '#548ADF',
      dark: '#00347D',
      contrastText: '#fff',
    },
    secondary: {
      main: '#F57C00',
      light: '#FFAD42',
      dark: '#BB4D00',
    },
    info: {
      main: '#2196F3',
      light: '#64B6F7',
      dark: '#0B79D0',
    },
    success: {
      main: '#4CAF50',
      light: '#7BC67E',
      dark: '#3B873E',
    },
    error: {
      main: '#F44336',
      light: '#F88078',
      dark: '#E31B0C',
      contrastText: '#fff',
    },
    warning: {
      main: '#ED6C02',
      light: '#FFB547',
      dark: '#C77700',
      contrastText: '#fff',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1536,
    },
  },
});

theme = createTheme(theme, {
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          overflowWrap: 'break-word',
        },
        ...typography,
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: AccentMainColor,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          lineHeight: '1.3',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          textTransform: 'none',
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          fontSize: theme.typography.pxToRem(14),
          //iOS+safari+mobileだと、フォームフォーカス時にフォントが16px未満だと見やすいサイズに自動でズームしてしまう
          [theme.breakpoints.down('md')]: {
            fontSize: theme.typography.pxToRem(16),
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          top: 'inherit',
        },
        select: {
          minHeight: '1.1876em',
        },
      },
      defaultProps: {
        variant: 'outlined',
      },
    },
    // Todo: #1519 にて調査
    // success.mainの色とのコントラストによって文字色が黒くなるため、共通のデザインカラーが決定するまでは暫定的にfilledのときは文字色を白に固定する
    MuiAlert: {
      styleOverrides: {
        filled: {
          color: '#FFF',
        },
      },
    },
  },
});

export default theme;
