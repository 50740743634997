import { Navigate, RouteObject } from 'react-router-dom';

import { AuthenticationGuard } from '@/Components/AuthenticationGuard';
import lazyImport from '@/Pages/LazyImport';

const BulkMessagePage = lazyImport(() => import('./Pages/BulkMessagePage'));
const FilePage = lazyImport(() => import('./Pages/FilePage'));
const HomePage = lazyImport(() => import('./Pages/HomePage'));
const ForbiddenPage = lazyImport(() => import('./Pages/ForbiddenPage'));
const Main = lazyImport(() => import('./Pages/Main'));
const MaintenancePage = lazyImport(() => import('./Pages/MaintenancePage'));
const MissingInitializedDB = lazyImport(
  () => import('./Pages/MissingInitializedDB'),
);
const NotFoundPage = lazyImport(() => import('./Pages/NotFoundPage'));
const NoLicense = lazyImport(() => import('./Pages/NoLicense'));
const AnalyticsBaitaiPage = lazyImport(
  () => import('./Pages/AnalyticsBaitaiPage'),
);
const AnalyticsTantoUserPage = lazyImport(
  () => import('./Pages/AnalyticsTantoUserPage'),
);
const GradeManagementPage = lazyImport(
  () => import('./Pages/GradeManagementPage'),
);
const SettingsPage = lazyImport(() => import('./Pages/SettingsPage'));
const CommonSettingsPage = lazyImport(
  () => import('./Pages/CommonSettingsPage'),
);
const TsuikyakuLogPage = lazyImport(() => import('./Pages/TsuikyakuLogPage'));
const TsuikyakuPage = lazyImport(() => import('./Pages/TsuikyakuPage'));
const GetLicenseErrorPage = lazyImport(
  () => import('./Pages/GetLicenseErrorPage'),
);
const LoginPage = lazyImport(() => import('./Pages/LoginPage'));

type SimpleRouteObject = Readonly<
  Required<Pick<RouteObject, 'path' | 'element'>>
>;

export const homeRoute: SimpleRouteObject = {
  path: '/home',
  element: <HomePage />,
};

export const tsuikyakuRoute: SimpleRouteObject = {
  path: '/tsuikyaku',
  element: <TsuikyakuPage title="追客案件" />,
};

export const tsuikyakuLogRoute: SimpleRouteObject = {
  path: '/tsuikyaku-log',
  element: <TsuikyakuLogPage />,
};

export const bulkMessageRoute: SimpleRouteObject = {
  path: '/bulk-message',
  element: <BulkMessagePage title="一括送信" />,
};

export const fileRoute: SimpleRouteObject = {
  path: '/file',
  element: <FilePage title="ファイル" />,
};

export const gradeManagementRoute: SimpleRouteObject = {
  path: '/grade-management',
  element: <GradeManagementPage title="契約進捗一覧" />,
};

export const analyticsBaitaiRoute: SimpleRouteObject = {
  path: '/analytics/baitai',
  element: <AnalyticsBaitaiPage />,
};

export const analyticsTantoUserRoute: SimpleRouteObject = {
  path: '/analytics/tanto-user',
  element: <AnalyticsTantoUserPage />,
};

export const settingsRoute: SimpleRouteObject = {
  path: '/settings',
  element: <SettingsPage title="設定" />,
};

export const commonSettingsRoute: SimpleRouteObject = {
  path: '/settings/common',
  element: <CommonSettingsPage title="設定" />,
};

export const missingInitializedDbRoute: SimpleRouteObject = {
  path: '/missing-initialized-db',
  element: <AuthenticationGuard component={MissingInitializedDB} />,
};

export const noLicenseRoute: SimpleRouteObject = {
  path: '/no-license',
  element: <AuthenticationGuard component={NoLicense} />,
};

export const forbiddenRoute: SimpleRouteObject = {
  path: '/forbidden',
  element: <AuthenticationGuard component={ForbiddenPage} />,
};

export const maintenanceRoute: SimpleRouteObject = {
  path: '/maintenance',
  element: <AuthenticationGuard component={MaintenancePage} />,
};

export const getLicenseErrorRoute: SimpleRouteObject = {
  path: '/get-license-error',
  element: <AuthenticationGuard component={GetLicenseErrorPage} />,
};

export const errorRoutes = [
  noLicenseRoute,
  forbiddenRoute,
  maintenanceRoute,
  getLicenseErrorRoute,
];

export const noPagingRoutes = [
  homeRoute,
  analyticsBaitaiRoute,
  analyticsTantoUserRoute,
];

export const tsuikyakuDetailRoutes = [
  homeRoute,
  tsuikyakuRoute,
  tsuikyakuLogRoute,
];

const routes: RouteObject[] = [
  {
    path: '/',
    element: <AuthenticationGuard component={Main} />,
    children: [
      tsuikyakuRoute,
      {
        path: `${tsuikyakuRoute.path}/:tsuikyakuGuid`,
        element: <TsuikyakuPage title="追客案件" />,
      },
      {
        path: `${tsuikyakuRoute.path}/:tsuikyakuGuid/:targetTab`,
        element: <TsuikyakuPage title="追客案件" />,
      },
      homeRoute,
      { path: `${homeRoute.path}/:tsuikyakuGuid`, element: <HomePage /> },
      {
        path: `${homeRoute.path}/:tsuikyakuGuid/:targetTab`,
        element: <HomePage />,
      },
      tsuikyakuLogRoute,
      {
        path: `${tsuikyakuLogRoute.path}/:tsuikyakuGuid`,
        element: <TsuikyakuLogPage />,
      },
      {
        path: `${tsuikyakuLogRoute.path}/:tsuikyakuGuid/:targetTab`,
        element: <TsuikyakuLogPage />,
      },
      bulkMessageRoute,
      fileRoute,
      gradeManagementRoute,
      {
        path: `${gradeManagementRoute.path}/:tsuikyakuGuid`,
        element: <GradeManagementPage />,
      },
      {
        path: `${gradeManagementRoute.path}/:tsuikyakuGuid/:targetTab`,
        element: <GradeManagementPage />,
      },
      analyticsBaitaiRoute,
      analyticsTantoUserRoute,
      settingsRoute,
      {
        path: `${settingsRoute.path}/:organizationGuid/:category`,
        element: <SettingsPage title="設定" />,
      },
      commonSettingsRoute,
      {
        path: `${commonSettingsRoute.path}/:category`,
        element: <CommonSettingsPage title="設定" />,
      },
      { path: '/', element: <Navigate to={homeRoute.path} /> },
    ],
  },
  { path: '404', element: <NotFoundPage /> },
  missingInitializedDbRoute,
  noLicenseRoute,
  forbiddenRoute,
  maintenanceRoute,
  getLicenseErrorRoute,
  { path: '/login', element: <LoginPage /> },
  { path: '*', element: <Navigate to="/404" /> },
];

export default routes;
